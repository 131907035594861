/* Styling untuk container utama */
.role-table-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 30px;
}

/* Styling untuk search bar */
.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Agar input tidak melewati lebar container */
}

/* Styling untuk tabel */
.role-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.role-table th, .role-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 16px;
}

.role-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.role-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.role-table tr:hover {
  background-color: #f1f1f1;
}

/* Styling untuk pagination */
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin: 0 5px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .role-table-container {
    padding: 15px;
  }

  .role-table th, .role-table td {
    font-size: 14px;
    padding: 8px;
  }

  .pagination button {
    font-size: 14px;
    padding: 8px 12px;
  }
}
