/* Workspace Container */
.workspace-container {
  background-color: transparent;
  min-height: 73vh;
  max-height: 73vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  width: 100%;
  border-radius: 10px;
}

/* Title */
.workspace-container h2 {
  font-size: 28px;
  font-weight: bold;
  color: #d32f2f;
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Cards Container */
.cards-container {
  display: flex;
  gap: 16px;
  padding: 10px;
  width: max-content;
  min-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #bbb transparent;
}

.cards-container::-webkit-scrollbar {
  height: 6px;
}

.cards-container::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 6px;
}

.cards-container::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

/* Card */
.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  min-height: 73vh;
  max-height: 73vh;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 12px;
  cursor: grab;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.15);
}

/* Card Header */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1.5px solid #ddd;
  width: 100%;
}

/* Title */
.card-header h3 {
  font-size: 18px;
  font-weight: 700;
  color: #333;
  margin: 0;
  word-break: break-word;
}

/* Close Button */
.close-btn {
  background: transparent;
  color: #d32f2f;
  border: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  padding: 4px;
  transition: color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.close-btn:hover {
  color: #b71c1c;
  transform: scale(1.1);
}

/* Card Body */
.card-body {
  min-height: 150px;
  max-height: 380px;
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #aaa transparent;
}

.card-body::-webkit-scrollbar {
  width: 6px;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 6px;
}

.card-body::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

/* Task List */
.task-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.task {
  max-width: 100%;
  width: 100%;
  word-break: break-word;
  white-space: pre-wrap;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 6px;
  margin-bottom: 6px;
  border: 1px solid #ddd;
  transition: background 0.2s ease-in-out;
}

.task:hover {
  background-color: #e9ecef;
}

.task:active {
  cursor: grabbing;
}

.dragging {
  opacity: 0.5;
  transform: scale(1.1);
}

/* Card Footer */
.card-footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  border-top: 1px solid #ddd;
}

/* Add Task Button */
.add-task-btn {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 14px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  transition: background 0.3s ease-in-out, transform 0.2s;
}

.add-task-btn:hover {
  background: #0056b3;
  transform: scale(1.05);
}

.add-task-btn:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

/* Add Board Button */
.add-board-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  min-width: 220px;
}

.add-board-container button {
  width: 250px;
  height: 36px;
  padding: 6px 12px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
}

.add-board-container input {
  width: 180px;
  height: 32px;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.add-board-btn {
  width: 100%;
  height: 36px;
  border-radius: 6px;
  background-color: #6e7071;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s ease-in-out, transform 0.2s;
}

.add-board-btn:hover {
  background-color: #8e9093;
  transform: scale(1.05);
}

.black-button {
  background-color: black;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.board-title-workspace {
  font-size: 18px;
  font-weight: 700;
  color: #333;
  margin: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 85%;
}

.task-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.task-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #aaa;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
  align-self: center;
  flex-shrink: 0;
}

.task-checkbox:checked {
  background-color: #50dd5e;
  border: 2px solid #50dd5e;
  position: relative;
}

.task-checkbox:checked::after {
  content: "✔";
  color: white;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
}

.task-title {
  flex-grow: 1;
}

.task-title.completed {
  color: #cac2c2;
}

.task-details {
  display: flex;
  flex-direction: column;
}

.task-icons {
  margin-top: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.task-icon {
  font-size: 14px;
  color: gray;
}

.task-attachment {
  display: flex;
  align-items: center;
  gap: 4px;
}

.attachment-count {
  font-size: 12px;
  color: gray;
}

.task-due-date {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}

.task-due-date i {
  font-size: 12px;
}

/* Warna berdasarkan kondisi */
.due-completed {
  background-color: green;
  color: white;
  padding: 3px;
}

.due-overdue {
  background-color: rgb(173, 9, 9);
  color: rgb(239, 192, 192);
  padding: 3px;
}

.task-cover {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  display: block;
  margin-bottom: 6px;
}

.task-assignees {
  display: flex;
  gap: 4px;
  margin-top: 8px;
  flex-wrap: wrap;
}

.assignee-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.task-labels {
  display: flex;
  gap: 4px;
}

.task-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

/* Responsive */
@media (max-width: 768px) {
  .workspace-container {
    padding: 10px;
  }

  .workspace-container h2 {
    font-size: 22px;
  }

  .card {
    width: 100%;
  }

  .add-task-btn {
    font-size: 12px;
    padding: 8px 12px;
  }

  .add-board-container {
    flex-direction: column;
    align-items: center;
  }

  .add-board-container input {
    width: 100%;
    margin-bottom: 6px;
  }

  .add-board-container button {
    width: 100%;
  }
}