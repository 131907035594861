/* Styling untuk container utama */
.user-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header tabel (search dan add button) */
.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.search-input {
  padding: 8px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-button {
  padding: 8px 15px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover {
  background: #0056b3;
}

/* Styling tabel */
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.user-table th {
  background: #f4f4f4;
}

/* Styling tombol aksi */
.action-button {
  padding: 6px 10px;
  margin: 2px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  background: #28a745;
  color: #fff;
}

.delete-button {
  background: #dc3545;
  color: #fff;
}

.reset-button {
  background: #ffc107;
  color: #fff;
}

.edit-button:hover {
  background: #218838;
}

.delete-button:hover {
  background: #c82333;
}

.reset-button:hover {
  background: #e0a800;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination-button {
  padding: 6px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #007bff;
  color: #fff;
}

.pagination-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background: #0056b3;
}