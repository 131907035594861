.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 1000px;
    height: 600px;
    position: relative;
    text-align: center;
    overflow-y: auto; 
    max-height: 80vh;
  }
  
  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .close-button svg {
    color: #333;
  }
  
  .close-button:hover svg {
    color: #ff4d4d;
  }
  