/* src/styles/Dashboard.css */
.dashboard {
  background-color: #ffffff;
  font-family: "Arial", sans-serif;
}

.charts {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.chart {
  flex: 1;
  max-width: 450px; /* Ukuran lebih besar tapi tetap proporsional */
  text-align: center;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chart canvas {
  width: 100% !important;
  height: 100% !important;
  max-width: 600px; /* Ukuran chart sedikit diperkecil */
}

.chart:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.pie-chart-container {
  margin-top: 15px;
}

h5 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .chart {
    width: 100%;
  }
}

/* Section Board Task */
.board-section {
  text-align: center;
  margin: 40px 0;
}

.board-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.board-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

/* Board Task */
.board {
  position: relative;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.board:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Icon Board */
.board-icon {
  font-size: 120px;
  color: #4a90e2;
  position: relative;
}

.board-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.new-label {
  background-color: #ff4757;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 4px;
  position: absolute;
  top: -10px;
  right: -15px;
}

/* Angka di tengah icon */
.count {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: bold;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Nama Board */
.board p {
  margin-top: 10px;
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

/* Role Table */
.role-table-container {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px; /* Jarak antar tabel lebih besar */
}

/* Menambah jarak antara Role Table 1 dan Role Table 2 */
.role-table-container + .role-table-container {
  margin-top: 50px;
}

.role-table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}

.role-table th, .role-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  white-space: normal; /* Pastikan teks tidak terlalu cepat terpotong */
  word-wrap: break-word;
}

/* Memberikan ukuran lebih besar khusus untuk kolom Message atau Info */
.role-table th:nth-last-child(2),
.role-table td:nth-last-child(2) {
  min-width: 300px; /* Sesuaikan ukuran */
  max-width: 500px;
  word-wrap: break-word;
  white-space: normal;
}

.role-table th {
  background-color: #e0e0e0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.pagination button {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.role-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.export-button {
  background-color: #28a745; /* Warna hijau */
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.export-button:hover {
  background-color: #218838;
}