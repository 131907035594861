/* Navbar Styling */
.navbar {
  background-color: #e42618;
  padding: 20px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo Styling */
.navbar-brand img {
  width: 50px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}

/* Navbar Right Section */
.navbar-right {
  display: flex;
  align-items: center;
  margin-left: auto; /* Mendorong elemen ke kanan */
  gap: 15px;
}

/* Notification Wrapper */
.notification-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Notification Badge */
.notification-badge {
  position: absolute;
  top: -5px;
  right: -2px;
  background-color: red;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  padding: 2px 6px;
  font-weight: bold;
  transition: transform 0.3s ease;
}

/* Notification Dropdown */
.notification-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 350px;
  padding: 10px;
  display: none;
  z-index: 100;
  max-height: 300px; /* Atur tinggi maksimum */
  overflow-y: auto;
}

/* Show dropdown when active */
.notification-wrapper.active .notification-dropdown {
  display: block;
}

/* Dropdown Item Styling */
.notification-dropdown .dropdown-item {
  padding: 8px 20px;
  font-size: 14px;
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.3s ease;
}

/* Hover Effect */
.notification-dropdown .dropdown-item:hover {
  background-color: #f1f1f1;
}

/* Notification Icon */
.notification-icon {
  font-size: 1.5rem;  /* Pastikan ukuran ikon cukup besar */
  margin-right: 8px;
  font-size: 28px; /* text-3xl */
  color: white;
}

.notification-icon.read {
  color: #10b981; /* text-green-500 */
}

.notification-icon.unread {
  color: #ef4444; /* text-red-500 */
}

/* User Section */
.user-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

/* User Icon */
.user-icon {
  font-size: 28px;
  color: white;
  margin-right: 10px;
}

/* User Details */
.user-details {
  color: white;
  font-size: 14px;
  text-align: left;
}

/* User Name Styling */
.user-name {
  font-weight: bold;
}

/* User Dropdown */
.user-dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 180px;
  z-index: 100;
  padding: 5px;
  display: none;
  transition: all 0.3s ease;
}

/* Show dropdown when active */
.user-section.active .user-dropdown {
  display: block;
}

/* Dropdown Item */
.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.3s ease;
}

/* Hover Effect */
.dropdown-item:hover {
  background-color: #f1f1f1;
}

.burger-menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: white; /* Sesuaikan dengan warna navbar */
  font-size: 1.5rem;
  padding: 8px 12px;
  margin-left: -15px; /* Geser lebih ke kiri */
  cursor: pointer;
}

.burger-menu-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.notification-item {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.notification-item:hover {
  background-color: #f3f4f6; /* bg-gray-100 */
}

.notification-title {
  display: block;
}

.notification-message {
  font-size: 12px; /* text-xs */
}


.sidebar-logo-img {
  width: 170px;
  height: 40px;
  object-fit: cover;
  border-radius: 8px;
  margin-left: 30px;
  position: relative;
  top: 0px;
}

.search-task-container {
  width: 300px;
  margin-right: 30px;
  position: relative;
}

.search-task-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.search-task-input:focus {
  border-color: #007bff;
  outline: none;
}

.search-task-dropdown {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.search-task-dropdown .dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.search-task-dropdown .dropdown-item:hover {
  background-color: #f0f0f0;
}

.search-task-message {
  font-size: 12px; /* text-xs */
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .navbar-right {
    display: none;
  }

  .burger-menu-btn {
    display: block;
    background-color: transparent;
    border: none;
  }

  .navbar-right.active {
    display: flex;
    align-items: center;
  }

  .notification-wrapper,
  .user-section {
    margin-right: 10px;
  }

  .notification-icon,
  .user-icon {
    font-size: 24px;
  }

  .notification-badge {
    top: -3px;
    right: -3px;
    font-size: 10px;
    padding: 2px 4px;
  }

  .user-details {
    display: none;
  }

  .notification-dropdown,
  .user-dropdown {
    position: static;
    display: none;
  }

  .notification-wrapper:hover .notification-dropdown,
  .user-section:hover .user-dropdown {
    display: block;
  }
}
